import React, { ReactNode, FC } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { mapObject } from 'underscore';
import constants from '../constants';

const { ENV, VERSION, BUGSNAG_KEY, DEBUG } = constants;

interface DebugWrapperProps {
    children: ReactNode;
}

const DebugWrapper: FC<DebugWrapperProps> = ({ children }) => {
    if (DEBUG || !BUGSNAG_KEY) {
        return (
            <>
                {children}
                {null} {/* This is to avoid the error: "Fragments should contain more than one child" */}
            </>
        );
    }

    Bugsnag.start({
        apiKey: BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact(React)],
        releaseStage: ENV,
        appVersion: VERSION,
    });

    const BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
    return BugsnagErrorBoundary ? <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary> : <>{children}{null}</>;
};

// const notify = (message:string, data:object[]) => {
//     if (DEBUG || !BUGSNAG_KEY) {
//         return;
//     }
//
//     const onError = (e: any) => mapObject(data, (value, key) => {
//         e.addMetadata(key, value || {});
//     });
//
//     Bugsnag.addOnError(onError);
//     Bugsnag.notify(new Error(message));
//     Bugsnag.removeOnError(onError);
// };

export default DebugWrapper;
