import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../../components/layout/AuthLayout';
import { registerUser } from '../../core/user/userApi';
import { setIsRegistered } from '../../core/user/userSlice';

function LoginPage() {

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { isFetching, isRegistered } = useSelector((state) => state.user);

    const handleSubmit = (values) => {
        console.log('Submit', values);
        dispatch(registerUser(values));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (isRegistered) {
            dispatch(setIsRegistered(false));
            navigation('/');
        }
    }, [isRegistered]);

    return (
        <AuthLayout>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="form-title h-1" style={{ marginBottom: 40 }}>Sign Up</h3>
                    <Form
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item name="name" rules={[{ required: true, message: 'Please input your full name' }]}>
                            <Input type="text" placeholder="Full Name" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="email" rules={[{ required: true, message: 'Please input a valid email' }, { type: 'email', message: 'The input is not a valid email!' }]}>
                            <Input type="text" placeholder="Email address" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the new password again',
                                },
                                {
                                    min: 8,
                                    message: 'Password must be at least 8 characters',
                                },
                            ]}>
                            <Input type="password" placeholder="Password" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item style={{ marginTop: 40 }}>
                            <Button type="primary" htmlType="submit" block>
                                Create Account
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button type="default" href="/" size="large" block>
                        Back
                    </Button>
                </div>
            </div>
        </AuthLayout>
    );
}
export default LoginPage;
