import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import Footer from '../../components/Footer/index';
import { postComment } from '../../core/user/userApi';
import './profile.scss';

const Support = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const dispatch = useDispatch();
    const { isFetching } = useSelector((state) => state.user);

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const handleSubmit = (values) => {
        console.log('Submit', values);
        dispatch(postComment(values));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleDeleteSubmit = (values) => {
        console.log('Submit', values);
        // dispatch(donate(values));
    };

    useEffect(() => {
        console.log('herere');
    }, []);

    const backLink = (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined />
        </Button>
    );

    return (
        <Main
            className="donate"
            title={<div>COPE UK ORG</div>}
            headerTitle={<div>Support</div>}
            menu={null}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter
            footerContent={<Footer />}>
            {/* <BreadcrumbNav separator=">" navigation={navigation} /> */}
            <Spin spinning={isFetching} style={{ width: '100%' }}>
                <div style={{ width: '100%', padding: '20px', backgroundColor: '#FFF' }}>
                    <Form
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item name="comment" rules={[{ required: true, message: 'Please input your questions or suggestions' }]}>
                            <Input.TextArea
                                rows={15}
                                placeholder="Please add your questions / suggestions here ..."
                                style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Main>
    );
};

export default Support;

Support.propTypes = {};
