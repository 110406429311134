import React, { useEffect } from 'react';
import { Button, Form, Select, Input, Modal, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { donate } from '../../../core/donate/donateApi';
import { updateClientSecret } from '../../../core/donate/donateSlice';

const DonateForm = ({ addNewCard }) => {
    const { isFetching, options, clientSecret, paymentMethodId } = useSelector((state) => state.donate);
    const { userData } = useSelector((state) => state.user);
    const [showAuthoriseModal, setShowAuthoriseModal] = React.useState(false);
    const dispatch = useDispatch();
    const stripe = useStripe();

    const projectOptions = options.projects && options.projects.map((project, index) => ({ label: project.name, value: project.id })) || [];
    const donationTypeOptions = options.donationTypes && options.donationTypes.map((project, index) => ({ label: project.name, value: project.id })) || [];
    const frequencyOptions = [
        { label: 'One Time', value: '0' },
        { label: 'Daily', value: '1' },
        { label: 'Weekly', value: '2' },
        { label: 'Fortnightly', value: '3' },
        { label: 'Monthly', value: '4' },
    ];

    const handleSubmit = (values) => {
        // {"donationAmount": "121", "donationType": 1, "frequency": "1", "project": 6}
        console.log('Submit', values);
        dispatch(donate(values));
    };

    const handleAuthorise = async () => {
        // Confirm the payment with the card details
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId,
        });

        setShowAuthoriseModal(false);
        dispatch(updateClientSecret(null));

        if (result.error) {
            // Show error to your customer
            notification.error({
                message: 'Payment Unsuccessful',
                description: 'Something went wrong with your authorisation. Please try again.',
            });
        } else if (result.paymentIntent.status === 'succeeded') {
            // The payment succeeded!
            notification.success({
                message: 'Payment Successful',
                description: 'Your payment was successful. Thank you for your donation.',
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (clientSecret !== null) {
            setShowAuthoriseModal(true);
        }
    }, [clientSecret]);

    return (
        <div style={{ width: '100%', padding: '20px', backgroundColor: '#FFF' }}>
            <Form
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}>
                <Form.Item label="Amount" name="donationAmount">
                    <Input type="number" placeholder="Enter amount" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Project" name="project">
                    <Select
                        options={projectOptions}
                        placeholder="Select a project"
                        style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Donation Type" name="donationType">
                    <Select
                        options={donationTypeOptions}
                        placeholder="Select a donation type"
                        style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Frequency" name="frequency">
                    <Select
                        options={frequencyOptions}
                        placeholder="Select a frequency"
                        style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        ALLAH, accept this donation from me
                    </Button>
                    <Button block style={{ marginTop: 20 }} onClick={() => addNewCard(true)}>
                        Add A New Card
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="Modal"
                open={showAuthoriseModal}
                onOk={handleAuthorise}
                onCancel={() => setShowAuthoriseModal(false)}
                okText="Authorise"
                cancelText="Cancel">
                You need to authorise the payment. Please click the Authorise button to proceed.
            </Modal>
        </div>

    );
};

DonateForm.propTypes = {
    addNewCard: PropTypes.func.isRequired,
};

export default DonateForm;
