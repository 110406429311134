import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import lockr from 'lockr';
import { showSuccess, showError } from '../utils/notify';
import { fetchApi, fetchApiAuth } from '../utils/api';
import { ResponseProps } from './types';
import constants from '../constants';

const {
    LOCALSTORAGE_EXPIRES_KEY,
    AUTH_KEY,
} = constants;

export const registerUser = createAsyncThunk(
    'user/register',
    async (payload:{email: string; password: string; name: string;}) => {
        const { email, password, name } = payload;
        const response:ResponseProps = await fetchApi({
            method: 'POST',
            url: 'register',
            body: {
                email,
                password,
                name,
            },
        });
        return response.data;
    },
);

export const loginUser = createAsyncThunk(
    'user/login',
    async (payload:{email: string; password: string;}, { rejectWithValue }) => {
        const { email, password } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'login',
                body: { email, password },
            });
            if (response.data.data.token) {
                const authDate = new Date();
                authDate.setMinutes(authDate.getMinutes() + 30);
                lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
                lockr.set(AUTH_KEY, response.data.data.token);
            } else if (response.data.data.twoFactorToken) {
                lockr.set('twoFactorToken', response.data.data.twoFactorToken);
            }
            return response.data.data;
        } catch (error:any) {
            if (!error.response) {
                throw error;
            }
            const errorMessage = error.response.data.message;
            notification.error({
                message: 'Error',
                description: errorMessage,
            });
            return rejectWithValue(errorMessage);
        }
    },
);

export const forgotPassword = createAsyncThunk(
    'user/forgotPassword',
    async (payload:{email: string;}, { rejectWithValue }) => {
        const { email } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'forgot-password',
                body: { email },
            });
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            const errorMessage = error.response.data.message;
            notification.error({
                message: 'Error',
                description: errorMessage,
            });
            return rejectWithValue(errorMessage);
        }

    },
);

export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (payload:{email: string|undefined; password: string; passwordConfirmation: string; token: string|undefined;}, thunkAPI) => {
        const { email, password, passwordConfirmation, token } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'reset-password',
                body: {
                    email,
                    password,
                    password_confirmation: passwordConfirmation,
                    token,
                },
            });
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            const errorMessage = error.response.data.message;
            notification.error({
                message: 'Error',
                description: errorMessage,
            });
            return thunkAPI.rejectWithValue(errorMessage);
        }
    },
);

export const logoutUser = createAsyncThunk(
    'user/logout',
    async () => {
        try {
            const response:ResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'logout',
            });
            lockr.rm(AUTH_KEY);
            lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
            localStorage.clear();
            return response.data;
        } catch (error:any) {
            if (!error.response) {
                throw error;
            }
            const errorMessage = error.response.data.message;
            notification.error({
                message: 'Error',
                description: errorMessage,
            });
            return errorMessage;
        }
    },
);

export const twoFactorConfirmation = createAsyncThunk(
    'user/twoFactorConfirmation',
    async (payload:{code: string;}) => {
        const { code } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'two-factor-confirmation',
                body: {
                    twoFactorToken: lockr.get('twoFactorToken'),
                    code,
                },
            });
            return response.data;
        } catch (error:any) {
            if (!error.response) {
                throw error;
            }
            const errorMessage = error.response.data.message;
            notification.error({
                message: 'Error',
                description: errorMessage,
            });
            return errorMessage;
        }
    },
);

export const twoFactorResend = createAsyncThunk(
    'user/twoFactorResend',
    async () => {
        try {
            const response = await fetchApi({
                method: 'POST',
                url: 'two-factor-resend',
                body: {
                    twoFactorToken: lockr.get('twoFactorToken'),
                },
            });
            return response.data;
        } catch (error:any) {
            if (!error.response) {
                throw error;
            }
            const errorMessage = error.response.data.message;
            notification.error({
                message: 'Error',
                description: errorMessage,
            });
            return errorMessage;
        }
    },
);

export const postChangePassword = createAsyncThunk(
    'user/postChangePassword',
    async ({ password, newPassword, confirmPassword }, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/change-password',
                body: {
                    password,
                    new_password: newPassword,
                    new_password_confirmation: confirmPassword,
                },
            });

            showSuccess('Success', 'Password changed successfully');
            return response.data;
        } catch (e) {
            const errorMessage = e.response?.data?.message || e.message || 'Error changing password';
            showError('Error', errorMessage);
            return rejectWithValue(errorMessage);
        }
    },
);

export const postComment = createAsyncThunk(
    'user/postComment',
    async ({ comment }, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/comment',
                body: { comment },
            });

            showSuccess('Success', 'Thank you for your feedback. We will get in touch with you soon');
            return response;
        } catch (e) {
            const errorMessage = e.response?.data?.message || e.message || 'Error posting support comment';
            showError('Error', errorMessage);
            return rejectWithValue(errorMessage);
        }
    },
);
