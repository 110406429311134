import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApiAuth } from '../utils/api';
import { showError, showSuccess } from '../utils/notify';

type DonatePropsType = {
    donationAmount: number;
    donationType: string;
    project: string;
    frequency: string;
}

export const getKpi = createAsyncThunk(
    'donate/getKpi',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'GET',
                url: '/donation/kpi',
            });
            console.log('>>>>>>>>> Response', response);
            return response?.data || [];
        } catch (e) {
            showError('Error', 'Something went wrong');
            const errorMessage = e.response?.data?.message || e.message || 'Error retrieving settings';

            return rejectWithValue(errorMessage);
        }
    },
);

export const getOptions = createAsyncThunk(
    'donate/options',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'GET',
                url: '/donation/options',
            });

            return response?.data || [];
        } catch (e) {
            showError('Error', 'Something went wrong');
            const errorMessage = e.response?.data?.message || e.message || 'Error updating settings';

            return rejectWithValue(errorMessage);
        }
    },
);

export const donate = createAsyncThunk(
    'donate/donate',
    async ({ donationAmount, donationType, project, frequency }: DonatePropsType, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/donate',
                body: {
                    amount: donationAmount,
                    donation_type: donationType,
                    project_id: project,
                    payment_method: 'STRIPE',
                    schedule: {
                        frequency,
                    },
                },
            });
            if (response?.data?.client_secret) {
                return response?.data;
            }
            showSuccess('Success', 'Thank you for your donation');
            return [];
        } catch (e) {
            showError('Error', 'Something went wrong');
            const errorMessage = e.response?.data?.message || e.message || 'Error updating settings';

            return rejectWithValue(errorMessage);
        }
    },
);

export const addCard = createAsyncThunk(
    'donate/addCard',
    async ({ cardToken }, { rejectWithValue }) => {
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: '/donate/add-card',
                body: { cardToken },
            });
            showSuccess('Success', 'Card added successfully');
            return response?.data || [];
        } catch (e) {
            showError('Error', 'Something went wrong');
            const errorMessage = e.response?.data?.message || e.message || 'Error updating settings';

            return rejectWithValue(errorMessage);
        }
    },
);
