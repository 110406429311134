import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import Footer from '../../components/Footer/index';
import { postChangePassword } from '../../core/user/userApi';
import './profile.scss';

const Profile = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const dispatch = useDispatch();
    const { isFetching, kpi, options } = useSelector((state) => state.donate);

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const handleSubmit = (values) => {
        dispatch(postChangePassword({
            password: values.currentPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmNewPassword,
        }));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleDeleteSubmit = (values) => {
        console.log('Submit', values);
        // dispatch(donate(values));
    };

    useEffect(() => {
        console.log('herere');
    }, []);

    const backLink = (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined />
        </Button>
    );

    const total = kpi.charges + kpi.subscriptions;

    return (
        <Main
            className="donate"
            title={<div>COPE UK ORG</div>}
            headerTitle={<div>Profile</div>}
            menu={null}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter
            footerContent={<Footer />}>
            {/* <BreadcrumbNav separator=">" navigation={navigation} /> */}
            <Spin spinning={isFetching} style={{ width: '100%' }}>
                <div style={{ width: '100%', padding: '20px', backgroundColor: '#FFF' }}>
                    <div style={{ marginBottom: 20 }}>Change your password</div>
                    <Form
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item name="currentPassword" rules={[{ required: true, message: 'Please input your current password' }]}>
                            <Input type="password" placeholder="Current Password" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="newPassword" rules={[{ required: true, message: 'Please input a new password' }]}>
                            <Input type="password" placeholder="New Password" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the new password again',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}>
                            <Input type="password" placeholder="Confirm New Password" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                Update Password
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                {/* <div style={{ width: '100%', padding: '20px', backgroundColor: '#FFF' }}> */}
                {/*     <Form */}
                {/*         onFinish={handleDeleteSubmit} */}
                {/*         onFinishFailed={onFinishFailed}> */}
                {/*         <Form.Item */}
                {/*             name="currentPassword" */}
                {/*             rules={[{ required: true, message: 'Please input your current password' }]}> */}
                {/*             <Input type="password" placeholder="Current Password" style={{ width: '100%' }} /> */}
                {/*         </Form.Item> */}
                {/*         <Button type="primary" danger block style={{ marginTop: 10 }}> */}
                {/*             DELETE ACCOUNT */}
                {/*         </Button> */}
                {/*     </Form> */}
                {/* </div> */}
            </Spin>
        </Main>
    );
};

export default Profile;

Profile.propTypes = {};
