import { isEmpty, mapObject } from 'underscore';
import { Button, message, Space, notification } from 'antd';
import type { NotificationArgsProps } from 'antd';

const showSuccess = (title, detail) => {
    notification.success({
        message: title,
        description: detail,
    });
    // Toast.show({
    //     type: 'success',
    //     position: 'top',
    //     text1: title,
    //     text2: detail,
    //     visibilityTime: 3000,
    //     autoHide: true,
    //     topOffset: 50,
    // });
};

const showError = (title, detail, error = null) => {
    let errorMessage = '';
    if (error && error.response && error.response.data) {
        const { errors } = error.response.data;
        if (errors && !isEmpty(errors)) {
            mapObject(errors, (value) => {
                errorMessage += value;
            });
        } else {
            errorMessage = error.response.data.message || '';
        }
    }

    notification.error({
        message: title,
        description: errorMessage || detail,
    });

    // Toast.show({
    //     type: 'error',
    //     position: 'top',
    //     text1: title,
    //     text2: errorMessage || detail,
    //     visibilityTime: 3000,
    //     autoHide: true,
    //     topOffset: 50,
    // });
};

export { showSuccess, showError };
