import React, { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import Footer from '../../components/Footer/index';
import { getKpi, getOptions } from '../../core/donate/donateApi';

import './dashboard.scss';

const Dashboard = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];

    const dispatch = useDispatch();
    const { isFetching, kpi, options } = useSelector((state) => state.donate);

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    useEffect(() => {
        dispatch(getKpi());
        dispatch(getOptions());
    }, []);

    const backLink = (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined />
        </Button>
    );

    const total = kpi.charges + kpi.subscriptions;

    return (
        <Main
            className="dashboard"
            title={<div>COPE UK ORG</div>}
            headerTitle={<div>Dashboard</div>}
            menu={null}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter
            footerContent={<Footer />}>
            {/* <BreadcrumbNav separator=">" navigation={navigation} /> */}
            <Spin spinning={isFetching} style={{ width: '100%' }}>
                <div className="content">
                    <div className="circle">
                        <div className="innerCircle">
                            <div className="title">Your Donations</div>
                            <div className="value">{formatter.format(total) || '0.00'}</div>
                        </div>
                    </div>
                </div>
                <div className="stats">
                    <div className="heading">Your donations breakdown</div>
                    <div className="breakdown">
                        <div className="stat">
                            <div className="title">Single Donations ({kpi.charges_count || '0'})</div>
                            <div className="value">{formatter.format(kpi.charges) || '0.00'}</div>
                        </div>
                        <div className="stat">
                            <div className="title">Schedule Donations ({kpi.subscriptions_count || '0'})</div>
                            <div className="value">{formatter.format(kpi.subscriptions) || '0.00'}</div>
                        </div>
                    </div>
                </div>

                <div className="project-stats">
                    {options.projects && options.projects.map((project, index) => (
                        <div className="statsBox" key={`project-${project.id}`}>
                            <div className="value">{project.completed_count || '0'}</div>
                            <div className="title">{project.name}</div>
                        </div>
                    ))}
                </div>
            </Spin>
        </Main>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
