import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '@fontsource/nunito-sans'; // Defaults to weight 400
import Routes from './core/routes';
// import history from './core/utils/history';
import DebugWrapper from './core/utils/debug';

import './styles/style.scss';
/** Actions: The necessary actions for dispatching our bootstrap values */
// import { setVersion } from './core/global/globalSlice';

/** configureStore */
import { store, persistor } from './core/store';

/** The version of the app but not displayed yet */
import pack from '../package.json';
import constants from './core/constants';

const VERSION = pack.version;
// const dispatch = useDispatch();
//
// dispatch(setVersion(VERSION));

const { STRIPE_PUBLIC_KEY } = constants;

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

console.log('Stripe Public Key:', STRIPE_PUBLIC_KEY);

// Load your Stripe publishable API key from environment variables
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

root.render(
    <React.StrictMode>
        <DebugWrapper>
            <Elements stripe={stripePromise} options={{ appearance: { theme: 'night' } }}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter
                            // history={history}
                            basename="/">
                            <Routes />
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </Elements>
        </DebugWrapper>
    </React.StrictMode>,
);
