/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
    loginUser,
    forgotPassword,
    resetPassword,
    registerUser,
    twoFactorConfirmation,
    twoFactorResend,
    logoutUser,
    postChangePassword,
    postComment,
} from './userApi';
import { UserState } from './types';

const initialState = {
    error: null,
    isFetching: false,
    isLoggedIn: false,
    showTwoFactorPage: false,
    userData: {},
    isRegistered: false,
} as UserState;

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsRegistered: (state:UserState, action) => {
            state.isRegistered = action.payload;
        },
        setUserDetailsHasCard: (state:UserState, action) => {
            state.userData.hasCard = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Login user
        builder
            .addCase(loginUser.fulfilled, (state:UserState, action) => {
                state.isFetching = false;
                if (action.payload.token) {
                    state.userData = action.payload.user;
                    state.isLoggedIn = true;
                } else if (action.payload.twoFactorToken) {
                    state.showTwoFactorPage = true;
                }
            });

        // Forgot password
        builder
            .addCase(forgotPassword.fulfilled, (state:UserState) => {
                state.isFetching = false;
                notification.success({
                    message: 'Success',
                    description: 'A one time password has been emailed to you, if an account exists you will receive an email shortly.',
                });
            });

        // Reset password
        builder
            .addCase(resetPassword.fulfilled, (state:UserState) => {
                state.isFetching = false;
                notification.success({
                    message: 'Success',
                    description: 'Your password has been reset, please login with your new password.',
                });
            });

        // Register user
        builder
            .addCase(registerUser.fulfilled, (state:UserState) => {
                state.isFetching = false;
                state.isRegistered = true;
                notification.success({
                    message: 'Success',
                    description: 'Your account has been created, please login.',
                });
            });

        // Two factor confirmation
        builder
            .addCase(twoFactorConfirmation.fulfilled, (state:UserState) => {
                state.isFetching = false;
                state.isLoggedIn = true;
                state.showTwoFactorPage = false;
                notification.success({
                    message: 'Success',
                    description: 'You have successfully logged in.',
                });
            });

        // Two factor resend
        builder
            .addCase(twoFactorResend.fulfilled, (state:UserState) => {
                state.isFetching = false;
                notification.success({
                    message: 'Success',
                    description: 'A new two factor code has been sent to your email.',
                });
            });

        builder
            .addCase(logoutUser.fulfilled, (state:UserState) => {
                state.isFetching = false;
                state.isLoggedIn = false;
                state.userData = {};
                notification.success({
                    message: 'Success',
                    description: 'You have successfully logged out.',
                });
                window.location.href = '/';
            });

        // Pending state for all requests
        builder.addMatcher(
            isAnyOf(
                loginUser.pending,
                forgotPassword.pending,
                resetPassword.pending,
                registerUser.pending,
                twoFactorConfirmation.pending,
                twoFactorResend.pending,
                logoutUser.pending,
            ),
            (state:UserState) => {
                state.isFetching = true;
                state.isLoggedIn = false;
                state.error = null;
                state.userData = {};
            },
        );
        builder.addMatcher(
            isAnyOf(
                twoFactorConfirmation.pending,
                twoFactorResend.pending,
                postChangePassword.pending,
                postComment.pending,
            ),
            (state:UserState) => {
                state.isFetching = true;
            },
        );

        builder.addMatcher(
            isAnyOf(
                postChangePassword.fulfilled,
                postComment.fulfilled,
            ),
            (state:UserState) => {
                state.isFetching = false;
            },
        );

        // Error state for all requests
        builder.addMatcher(
            isAnyOf(
                loginUser.rejected,
                forgotPassword.rejected,
                resetPassword.rejected,
                registerUser.rejected,
                twoFactorConfirmation.rejected,
                twoFactorResend.rejected,
                logoutUser.rejected,
                postChangePassword.rejected,
                postComment.rejected,
            ),
            (state:UserState, action) => {
                state.isFetching = false;
                state.error = action.error;
            },
        );
    },
});

export const { setIsRegistered, setUserDetailsHasCard } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
