/* eslint no-param-reassign: 0 */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getKpi, getOptions, donate, addCard } from './donateApi';

const initialState = {
    kpi: {},
    options: {},
    details: {},
    isFetching: false,
    error: null,
    clientSecret: null,
    paymentMethodId: null,
};

export const donateReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.details = action.payload;
        },
        updateClientSecret: (state, action) => {
            state.clientSecret = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addCard.fulfilled, (state, action) => {
            state.isFetching = false;
            state.error = null;
        });
        builder.addCase(getKpi.fulfilled, (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.kpi = action.payload;
        });
        builder.addCase(getOptions.fulfilled, (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.options = action.payload;
        });
        builder.addCase(donate.fulfilled, (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.clientSecret = action.payload?.client_secret || null;
            state.paymentMethodId = action.payload?.payment_method || null;
        });

        builder.addMatcher(
            (action) => [
                getKpi.pending.type,
                getOptions.pending.type,
                donate.pending.type,
                addCard.pending.type,
            ].includes(action.type),
            (state) => {
                state.isFetching = true;
                state.error = null;
            },
        );
        builder.addMatcher(
            (action) => [
                getKpi.rejected.type,
                getOptions.rejected.type,
                donate.rejected.type,
                addCard.rejected.type,
            ].includes(action.type),
            (state, action) => {
                state.isFetching = false;
                state.error = action.error;
            },
        );
    },
});

export const {
    setUserDetails,
    updateClientSecret,
} = donateReducer.actions;

export default donateReducer.reducer;
