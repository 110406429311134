import React, { FC } from 'react';
import { CoreRouter, LogoutRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Donate from '../pages/Donate';
import Profile from '../pages/Profile';
import Support from '../pages/Support';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import TwoFactorAuth from '../pages/TwoFactorAuth';

const Routes: FC = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/register', component: Register },
        { path: '/dashboard', component: Dashboard, type: 'auth' },
        { path: '/donate', component: Donate, type: 'auth' },
        { path: '/profile', component: Profile, type: 'auth' },
        { path: '/support', component: Support, type: 'auth' },
        { path: '/reset-password/:token/:email', component: ResetPassword, type: 'default' },
        { path: '/two-factor', component: TwoFactorAuth, type: 'default' },
        { path: '/logout', component: LogoutRoute, type: 'default' },
        { path: '/auth/dashboard', component: Dashboard, type: 'auth' },
    ]} />
);

export default Routes;
