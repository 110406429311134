import React, { useEffect } from 'react';
import { BottomNavigation } from 'reactjs-bottom-navigation';
import { useNavigate, useLocation } from 'react-router-dom';
import { HomeOutlined, HeartOutlined, UserOutlined, QuestionOutlined } from '@ant-design/icons';
import history from '../../core/utils/history';
import '../../styles/_elements.scss';

export default function Footer() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    let initialActiveButton = 0;
    switch (pathname) {
    case '/dashboard':
        initialActiveButton = 0;
        break;
    case '/donate':
        initialActiveButton = 1;
        break;
    case '/profile':
        initialActiveButton = 2;
        break;
    case '/support':
        initialActiveButton = 3;
        break;
    default:
        initialActiveButton = 0;
    }
    const [activeButton, setActiveButton] = React.useState(initialActiveButton);

    // useEffect();

    const bottomNavItems = [
        {
            title: 'Home',
            onClick: ({ id }) => {
                setActiveButton(0);
                navigate('/dashboard');
            },
            icon: <HomeOutlined />, // just for example
            activeIcon: <HomeOutlined color="#fff" />,
        },
        {
            title: 'Donate',
            onClick: ({ id }) => {
                setActiveButton(1);
                navigate('/donate');
            },
            icon: <HeartOutlined />, // just for example
            activeIcon: <HeartOutlined color="#fff" />,
        },
        {
            title: 'Profile',
            onClick: ({ id }) => {
                setActiveButton(2);
                navigate('/profile');
            },
            icon: <UserOutlined />, // just for example
            activeIcon: <UserOutlined color="#fff" />,
        },
        // the render method enables custom item content
        {
            title: 'Support',
            onClick: ({ id }) => {
                setActiveButton(3);
                navigate('/support');
            },
            icon: <QuestionOutlined />, // just for example
            activeIcon: <QuestionOutlined color="#fff" />,
        },
    ];
    return (
        <BottomNavigation
            items={bottomNavItems}
            selected={activeButton}
            onItemClick={(item) => console.log(item)}
            activeBgColor="#03451F"
            activeTextColor="white" />
    );

    // return (
    //     <div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2020</div>
    // );
}
