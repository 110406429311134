import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { map, isNull } from 'underscore';
import { getBaseUrl } from '../../../core/utils/api';
import userAvatar from '../../../assets/images/default-avatar.png';
import { MenuProps } from './types';

const Menu:FC<MenuProps> = ({
    user,
    items,
    logo,
    placement,
    width,
}) => {

    const [menuOpen, setMenuOpen] = useState(false);

    const openDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    return (
        <div className="menu">
            <Button
                style={{ paddingTop: 0, background: 'none', border: 0, color: '#20AAE4', fontSize: '30px', boxShadow: '0' }}
                className="menu-button"
                onClick={openDrawer}>
                <MenuOutlined />
            </Button>
            <Drawer
                open={menuOpen}
                onClose={closeDrawer}
                placement={placement}
                width={width}>
                <div className="menu__header">
                    {!isNull(logo)
                        ? (
                            <Link to="/">
                                <img className="github" width={256} src={logo} alt="" />
                            </Link>
                        )
                        : null}
                </div>
                <div className="menu__content">
                    <ul className="menu__nav">
                        {map(items, (item, itemIndex) => (
                            <li key={item.key || `menu-item-${itemIndex}`}>
                                <Link to={item.link} className={`menu__navlink ${item.className || ''}`} style={item.style || null}>

                                    <span>{item.label}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="menu__footer">
                    <div className="profile">
                        <img src={user && user.profile ? `${getBaseUrl()}${user.profile}` : userAvatar} className="profile__avatar" alt="" />
                        <div className="profile__info">
                            <a href={`mailto:${user && user.email}`}>{user && user.email}</a>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default Menu;
