import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { addCard } from '../../../core/donate/donateApi';
import { setUserDetailsHasCard } from '../../../core/user/userSlice';

const AddCard = ({ addNewCard }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (cardElement) {
            const result = await stripe.createToken(cardElement);

            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                console.log(result.error.message);
            } else {
                // Send the token to your server to create a charge or customer
                const token = result.token.id || null;
                dispatch(addCard({ cardToken: token }));
                addNewCard(false);
                dispatch(setUserDetailsHasCard(true));
            }
        }
    };

    return (
        <div style={{ width: '100%', padding: '20px', backgroundColor: '#FFF', marginBottom: 40 }}>
            <div style={{ marginBottom: 20 }}>Please enter your card details</div>
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }} />
                <Button htmlType="submit" type="primary" block disabled={!stripe} style={{ marginTop: 40 }}>
                    Add Card
                </Button>
            </form>
            <Button block style={{ marginTop: 20 }} onClick={() => addNewCard(false)}>
                Cancel
            </Button>
        </div>

    );
};

AddCard.propTypes = {
    addNewCard: PropTypes.func.isRequired,
};

export default AddCard;
