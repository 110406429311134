import { combineReducers } from 'redux';
import global from './global/globalSlice';
import user from './user/userSlice';
import donate from './donate/donateSlice';
/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    donate,
});
export default appReducers;
