import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import Footer from '../../components/Footer/index';
import { getKpi, getOptions } from '../../core/donate/donateApi';
import AddCard from './AddCard';
import DonateForm from './DonateForm';
import './donate.scss';

const Donate = () => {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const dispatch = useDispatch();
    const { isFetching, kpi, options } = useSelector((state) => state.donate);
    const { userData } = useSelector((state) => state.user);
    const [addNewCard, setAddNewCard] = useState(false);

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    useEffect(() => {
        dispatch(getKpi());
        dispatch(getOptions());
    }, []);

    const backLink = (
        <Button onClick={() => console.log('Back')}>
            <LeftOutlined />
        </Button>
    );

    const total = kpi.charges + kpi.subscriptions;

    return (
        <Main
            className="donate"
            title={<div>COPE UK ORG</div>}
            headerTitle={<div>Donate</div>}
            menu={null}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            floatingFooter
            footerContent={<Footer />}>
            {/* <BreadcrumbNav separator=">" navigation={navigation} /> */}
            <Spin spinning={isFetching} style={{ width: '100%' }}>
                {!userData.hasCard || addNewCard
                    ? <AddCard addNewCard={setAddNewCard} />
                    : <DonateForm addNewCard={setAddNewCard} />}
            </Spin>
        </Main>
    );
};

export default Donate;

Donate.propTypes = {};
