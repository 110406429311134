import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
// import { Logo } from 'components/Elements/CustomSVGIcon';
import icon from '../../../assets/favicon.ico';
import './authLayout.scss';
import constants from '../../../core/constants';
import { LayoutProps } from './types';
import Logo from '../../../assets/images/cope-logo-small.png';

const { APP_NAME } = constants;

const AuthLayout:FC<LayoutProps> = ({ children }) => (
    <div className="login-wrap">
        <Helmet
            title={`${APP_NAME}`}
            titleTemplate={`${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' },
            ]}>
            <link rel="shortcut icon" href={icon} />
        </Helmet>
        <div className="login-info">
            <div className="bg-absolute " />
            <div className="login-info__text">
                <img src={Logo} alt="logo" className="main-logo" />
                {/* <Logo h={202} w={500} /> */}
                {/* <img src={logoSrc} alt="logo" className="main-logo" /> */}
                {/* <h2 className="title">Client Portal</h2> */}
                {/* <p className="description"> */}
                {/*    Welcome to the client Internal Compliance Plan (ICP) Review Portal. */}
                {/*    The purpose of this tool is to provide a secure platform where your Internal Compliance */}
                {/*    Programme (ICP) can be used to assess the export control risk exposure of the business. */}
                {/*    Following submission it will be compared with internationally recommended best practice and a bespoke gap analysis performed. */}
                {/* </p> */}
            </div>
        </div>
        {children}
    </div>
);

export default AuthLayout;
