import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../core/user/userApi';
import './resetPasswordForm.scss';
import { FinishedFailedProps, valuesProps, ResetPasswordFormProps } from './types';
import { AppDispatch, CombinedStates } from '../../../core/types';

const ResetPasswordForm:FC<ResetPasswordFormProps> = ({ backLink }) => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        isFetching,
    } = useSelector((state:CombinedStates) => state.user);
    const { token, email } = useParams();
    const [form] = Form.useForm();

    const onFinish = (values: valuesProps) => {
        dispatch(resetPassword({
            email,
            password: values.newPassword,
            passwordConfirmation: values.confirmPassword,
            token,
        }));
    };

    const onFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    return (

        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title h-1">Reset Password</h3>
                <Form onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
                    <Form.Item label="New Password" name="newPassword" rules={[{ required: true }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label="Confirm Password"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Row>
                        <Col xs={24}>
                            <Button type="primary" size="large" block htmlType="submit" className="wide" loading={isFetching}>
                                Reset Password
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </div>

    );
};

export default ResetPasswordForm;
